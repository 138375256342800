<script setup lang="ts">
const props = defineProps<{
  active: boolean
}>()
</script>

<template>
  <button
    class="h-3.4rem w-3.4rem flex items-center justify-center overflow-hidden focus hover:focusActive rounded-full bg-starline-secondary transition duration-500"
    :class="props.active ? 'focusActive' : ''"
  >
    <slot />
  </button>
</template>

<style scoped>

</style>
