<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 13.125C9.31066 13.125 9.5625 13.3768 9.5625 13.6875V15.5625C9.5625 15.8732 9.31066 16.125 9 16.125C8.68934 16.125 8.4375 15.8732 8.4375 15.5625V13.6875C8.4375 13.3768 8.68934 13.125 9 13.125Z" fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 1.875C9.31066 1.875 9.5625 2.12684 9.5625 2.4375V4.3125C9.5625 4.62316 9.31066 4.875 9 4.875C8.68934 4.875 8.4375 4.62316 8.4375 4.3125V2.4375C8.4375 2.12684 8.68934 1.875 9 1.875Z" fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 7.3125C8.06802 7.3125 7.3125 8.06802 7.3125 9C7.3125 9.93198 8.06802 10.6875 9 10.6875C9.93198 10.6875 10.6875 9.93198 10.6875 9C10.6875 8.06802 9.93198 7.3125 9 7.3125ZM6.1875 9C6.1875 7.4467 7.4467 6.1875 9 6.1875C10.5533 6.1875 11.8125 7.4467 11.8125 9C11.8125 10.5533 10.5533 11.8125 9 11.8125C7.4467 11.8125 6.1875 10.5533 6.1875 9Z" fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.08462 11.9177C6.30429 12.1374 6.30429 12.4935 6.08462 12.7132L4.75712 14.0407C4.53745 14.2603 4.1813 14.2603 3.96163 14.0407C3.74196 13.821 3.74196 13.4649 3.96163 13.2452L5.28913 11.9177C5.5088 11.698 5.86495 11.698 6.08462 11.9177Z" fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0417 3.95967C14.2613 4.17934 14.2613 4.5355 14.0417 4.75517L12.7142 6.08267C12.4945 6.30234 12.1383 6.30234 11.9187 6.08267C11.699 5.863 11.699 5.50684 11.9187 5.28717L13.2462 3.95967C13.4658 3.74 13.822 3.74 14.0417 3.95967Z" fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.875 9C1.875 8.68934 2.12684 8.4375 2.4375 8.4375H4.3125C4.62316 8.4375 4.875 8.68934 4.875 9C4.875 9.31066 4.62316 9.5625 4.3125 9.5625H2.4375C2.12684 9.5625 1.875 9.31066 1.875 9Z" fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.125 9C13.125 8.68934 13.3768 8.4375 13.6875 8.4375H15.5625C15.8732 8.4375 16.125 8.68934 16.125 9C16.125 9.31066 15.8732 9.5625 15.5625 9.5625H13.6875C13.3768 9.5625 13.125 9.31066 13.125 9Z" fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96163 3.95967C4.1813 3.74 4.53745 3.74 4.75712 3.95967L6.08462 5.28717C6.30429 5.50684 6.30429 5.863 6.08462 6.08267C5.86495 6.30234 5.5088 6.30234 5.28913 6.08267L3.96163 4.75517C3.74196 4.5355 3.74196 4.17934 3.96163 3.95967Z" fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9187 11.9177C12.1383 11.698 12.4945 11.698 12.7142 11.9177L14.0417 13.2452C14.2613 13.4649 14.2613 13.821 14.0417 14.0407C13.822 14.2603 13.4658 14.2603 13.2462 14.0407L11.9187 12.7132C11.699 12.4935 11.699 12.1374 11.9187 11.9177Z" fill="white" />
  </svg>
</template>
